import { render, staticRenderFns } from "./Imarui.vue?vue&type=template&id=7ed7f9cb&scoped=true&"
import script from "./Imarui.vue?vue&type=script&lang=js&"
export * from "./Imarui.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ed7f9cb",
  null
  
)

export default component.exports